import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/app/[locale]/globals.css");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/app/theme.ts");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/layout/Header.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/shared/CookieConsent.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/shared/HeroNew.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/shared/Image.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/shared/ImageBlock.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/components/shared/index.ts");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/lib/context/bugsnag-context.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/Container/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/Grid/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/List/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/ListItem/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/ListItemText/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/styles/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/var/www/vhosts/webbeukers.nl/repository_webbeukers_LIVE/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/fonts/index.ts\",\"import\":\"Blinker\",\"arguments\":[{\"variable\":\"--font-blinker\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"]}],\"variableName\":\"blinker\"}")